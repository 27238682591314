import httpClient from "@/api/httpClient";

const WISHLIST_BASE_URL = "/wishlist";

const getWishlists = (chartmetricID) =>
  httpClient.get(`${WISHLIST_BASE_URL}?chartmetricID=${chartmetricID}`);

const getWishlistByWishlistID = (wishlistID) =>
  httpClient.get(`${WISHLIST_BASE_URL}/${wishlistID}`);

const createWishlist = (payload) =>
  httpClient.post(`${WISHLIST_BASE_URL}`, payload);

const addPlaylistsToExistingWishlist = (payload) =>
  httpClient.put(`${WISHLIST_BASE_URL}`, payload);

const deleteWishlist = (wishlistID) =>
  httpClient.delete(`${WISHLIST_BASE_URL}/${wishlistID}`);

export {
  getWishlists,
  getWishlistByWishlistID,
  createWishlist,
  addPlaylistsToExistingWishlist,
  deleteWishlist,
};
